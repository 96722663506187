import {Button, IconButton, Stack} from "@mui/material";
import {Add, CloseOutlined} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import {useNewConversationId} from "../../../../providers/hooks/use-new-conversation-id";
import {UuidProvider} from "../../../../providers/utils/uuid-provider";

const ChatRecentHeader = ({onToggleSideBar, headerRef}) => {
    const [conversationId, setConversationId] = useNewConversationId();

    const handleNewConversation = (event) => {
        setTimeout(async () => {
            setConversationId(await UuidProvider());
        }, 0);
    }

    return <Stack
        ref={headerRef}
        direction="column"
        alignItems="flex-start">
        <IconButton size="large" onClick={onToggleSideBar}>
            <CloseOutlined size="large"/>
        </IconButton>
        <Stack
            flexDirection="row"
            width="100%"
            marginY={{xs: 1, sm: 2, md: 8}}>
            <Button
                component={RouterLink}
                variant="outlined"
                startIcon={<Add/>}
                color="primary"
                align="left"
                to={"/chat/" + conversationId}
                onClick={handleNewConversation}
                sx={{
                    justifyContent: "flex-start",
                    minWidth: "80%",
                    borderRadius: "25px"
                }}>
                New Chat
            </Button>
        </Stack>
    </Stack>;
};

export default ChatRecentHeader;
