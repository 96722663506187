import React,{useState} from 'react';
import { Grid2 as Grid, Box, TextField, Typography, Button, Link, Snackbar, Alert} from "@mui/material";

import { ArrowOutward } from '@mui/icons-material';

const ContactUsForm = ({ onFormSubmit }) => {
    const inputStyles = {
        '& label': {
            fontSize: '18px'
        },
        '& input': {
            fontSize: '19px'
        },
        '& .MuiInput-root': {
            marginTop: '20px',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomWidth: '1px',
        },
        '& .MuiInput-underline:after': {
            borderBottomWidth: '1px',
        }
    };

    const linkStyles = {
        textDecoration: 'underline',
        textUnderlineOffset: '10px',
        color: '#0000FF',
    };


    const [formData, setFormData] = useState({
        fullname: '',
        contact: '',
        message: ''
    })


    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [errors, setErrors] = useState({
        contact: { error: false, message: '' }
    });

    const isValidPhoneOrEmail = (input) => {
        const trimmedInput = input.trim();
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneRegex = /^(\+?\d{1,4}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;

        if (emailRegex.test(trimmedInput)) {
            return { isValid: true, type: 'email' };
        }

        const digitsOnly = trimmedInput.replace(/\D/g, '');
        if (phoneRegex.test(trimmedInput) && digitsOnly.length >= 10) {
            return { isValid: true, type: 'phone' };
        }

        return { isValid: false, type: null };
    };
    const resetForm = () => {
        setFormData({
            fullname: '',
            contact: '',
            message: ''
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === 'contact' && value.trim() !== '') {
            const validationResult = isValidPhoneOrEmail(value);
            setErrors(prev => ({
                ...prev,
                contact: {
                    error: !validationResult.isValid,
                    message: !validationResult.isValid
                        ? 'Please enter a valid email or phone number'
                        : ''
                }
            }));
        } else if (name === 'contact' && value.trim() === '') {
            setErrors(prev => ({
                ...prev,
                contact: { error: false, message: '' }
            }));
        }
    };




    const handleSubmit = async(e) => {
        e.preventDefault();
        const contactValidation = isValidPhoneOrEmail(formData.contact);
        if (!contactValidation.isValid) {
            setErrors(prev => ({
                ...prev,
                contact: {
                    error: true,
                    message: 'Please enter a valid email or phone number'
                }
            }));
            return;
        }

        try {
            const response = await onFormSubmit(formData);
            if (response.success) {
                setShowSuccessMessage(true);
                let message = `
                Thank you for reaching out! We've received your message and our team will be in touch shortly.
                `
                setSuccessMessage(message);
                resetForm();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    return (
        <Grid item="true" xs={12} md={6}>
            <form onSubmit={handleSubmit} method='POST'>
                <Grid sx={8}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, }}>

                        <TextField
                            fullWidth
                            name='fullname'
                            label="Full Name"
                            variant="standard"
                            sx={inputStyles}
                            value={formData.fullname}
                            onChange={handleInputChange}
                        />

                        <TextField
                            fullWidth
                            name='contact'
                            label="Email or Phone"
                            variant="standard"
                            type="email || tel"
                            sx={inputStyles}
                            value={formData.contact}
                            onChange={handleInputChange}
                            error={errors.contact.error}
                            helperText={errors.contact.message}
                        />
                        <TextField
                            variant="standard"
                            minRows={4}
                            multiline
                            name="message"
                            label="Message"
                            helperText="Leave us a message"
                            sx={inputStyles}
                            value={formData.message}
                            onChange={handleInputChange}
                        />

                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type='submit'
                                variant="contained"
                                endIcon={<ArrowOutward />}
                                sx={{
                                    bgcolor: '#4285f4',
                                    px: 4,
                                    py: 1.5,
                                    textTransform: 'none',
                                    borderRadius: '28px',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    '&:hover': {
                                        bgcolor: '#3367d6'
                                    },
                                    '& .MuiButton-endIcon': {
                                        ml: 1
                                    }
                                }}
                            >
                                Send
                            </Button>
                        </Box>
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'text.secondary',
                                mt: 2,
                                fontSize: '18px'
                            }}
                        >
                            This site is protected by reCAPTCHA and the Google{' '}
                            <Link href="#" sx={linkStyles} style={{ textDecoration: 'underlined', color: 'secondary' }}>
                                Privacy Policy
                            </Link>
                            {' '}and{' '}
                            <Link href="#" sx={linkStyles} style={{ textDecoration: 'underlined', color: 'secondary' }}>
                                Terms of Service
                            </Link> apply.
                        </Typography>
                    </Box>
                </Grid>
                <Snackbar
                    open={showSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => setShowSuccessMessage(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    style={{ top: '16px', right: '16px' }}

                >
                    <Alert
                        onClose={() => setShowSuccessMessage(false)}
                        severity="success"
                        sx={{
                            color: '#fff',
                            backgroundColor: '#3c7af1',
                            border: '1px solid #3c7af1',
                            borderColor: 'rgba(60, 122, 241, 0.5)',
                            '& .MuiAlert-icon': {
                                color: '#fff'
                            }
                        }}
                    >
                        {successMessage}
                    </Alert>
                </Snackbar>
            </form>
        </Grid>

    );
}

export default ContactUsForm;
