import {Container, Grid2 as Grid} from "@mui/material";
import ChatRecent from "../../layout/pages/chat/recent";
import ChatMain from "../../layout/pages/chat/main";
import {useSideBarState} from "../../providers/hooks/use-side-bar-state";
import {useHeaderHeight} from "../../providers/hooks/use-header-height";


const PageChat = ({headerRef}) => {

    const [isSideBarOpen, setSideBarOpen] = useSideBarState();

    const onToggleSideBar = (event) => {
        setSideBarOpen(!isSideBarOpen)
    }

    const {headerHeight} = useHeaderHeight({headerRef, initialHeight: 96});

    return <Grid
        container
        spacing={1}
        columns={24}
        direction={{xs: "row-reverse", md: "row"}}
        sx={{
            flexGrow: 1,
            height: `calc(100vh - ${headerHeight}px)`,
            "& > *": {
                transition: "width .25s ease-out"
            }
        }}>
        <Grid
            size={{
                xs: isSideBarOpen ? 0 : 1,
                md: isSideBarOpen ? 8 : 1,
                lg: isSideBarOpen ? 6 : 1,
                xl: isSideBarOpen ? 4 : 1
            }}>
            <ChatRecent isSideBarOpen={isSideBarOpen} onToggleSideBar={onToggleSideBar} headerHeight={headerHeight}/>
        </Grid>
        <Grid
            component={Container}
            size="grow"
            sx={{
                flexGrow: 1,
                height: "100%"
            }}>
            <ChatMain/>
        </Grid>
    </Grid>;
};

export default PageChat;
