import {ServerUrlProvider} from "../utils/server-url-provider";

const PromptProvider = async () => {
    try {
        const path = ServerUrlProvider('/prompts?location=homepage');

        const response = await fetch(path);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error in PromptProvider:', error);
        throw error;
    }

};

export {PromptProvider};

