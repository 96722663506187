import {Button, IconButton, Stack, Typography} from "@mui/material";
import {ArrowCircleRightOutlined} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import {alpha} from "@mui/material/styles";
import {useNewConversationId} from "../../../../providers/hooks/use-new-conversation-id";

const HomePromptItem = ({title}) => {
    const [conversationId] = useNewConversationId();

    return <>
        <Button
            component={RouterLink}
            variant="outlined"
            color="secondary"
            size="small"
            to={"/chat/" + conversationId}
            state={{question: title.template}}
            sx={{
                minWidth: "initial",
                marginY: 1,
                paddingY: 0.5,
                paddingX: 1,
                whiteSpace: "nowrap",
                textTransform: "initial",
                borderWidth: "3px",
                borderRadius: "5px",
                fontWeight: "bold",
                display: {xs: "inline-block", md: "none"},
            }}>
            {title.template}
        </Button>
        <Button
            component={RouterLink}
            variant="contained"
            color="background.paper"
            to={"/chat/" + conversationId}
            state={{question: title.template}}
            sx={{
                height: 140,
                minWidth: 240,
                width: 240,
                display: {xs: "none", md: 'inline-flex'},
                padding: 0,
                borderRadius: '15px',
                textTransform: 'none',
                "&:hover": {
                    height: "fit-content",
                    transform: 'scale(1.05)',
                },
                "&, & *": {
                    transition: 'all 0.25s linear',
                }
            }}>
            <Stack sx={[
                (theme) => ({
                    height: "100%",
                    width: "100%",
                    borderRadius: 2,
                    flexDirection: 'column',
                    justifyContent: "space-between",
                    borderTop: '16px solid ' + theme.palette.secondary.main,
                    ...theme.applyStyles('dark', {
                        borderRight: '1px solid ' + theme.palette.secondary.main,
                        borderLeft: '1px solid ' + theme.palette.secondary.main,
                        borderBottom: '1px solid ' + theme.palette.secondary.main
                    }),
                    "&:hover": {
                        height: "auto",
                        borderTopWidth: '40px',
                        borderTopColor: "transparent",
                        backgroundOrigin: "border-box",
                        backgroundClip: "padding-box, border-box",
                        borderRight: 'none',
                        borderLeft: 'none',
                        borderBottom: 'none',
                        backgroundImage: `linear-gradient(${alpha(theme.palette.background.paper, 0.95)}, ${alpha(theme.palette.background.paper, 0.95)}), linear-gradient(to right, ${theme.palette.tertiary.main}, ${theme.palette.secondary.main})`,
                        "& .title-prompt": {
                            fontSize: '1.1rem',
                            paddingTop: 1,
                            paddingBottom: 0,
                            textAlign: "center",
                            "-webkitLineClamp": "3",
                        },
                        "& .title-ask-this": {
                            opacity: 1
                        }
                    },
                })
            ]}>
                <Typography
                    variant="body1"
                    className="title-prompt"
                    component="p"
                    color="primary"
                    textAlign="start"
                    paddingX={2}
                    paddingTop={3}
                    sx={{
                        width: "100%",
                        textAlign: "start",
                        display: "-webkit-box",
                        "-webkitLineClamp": "2",
                        "-webkitBoxOrient": "vertical",
                        overflow: "hidden",
                        minHeight: "4rem"
                    }}>
                    {title.template}
                </Typography>
                <Stack
                    paddingX={3}
                    paddingBottom={1}
                    sx={{
                        width: "100%",
                        justifyContent: 'space-between',
                        alignItems: "center",
                        flexDirection: 'row',
                        flexWrap: 'nowrap'
                    }}>
                    <Typography
                        component="small"
                        className="title-ask-this"
                        variant="subtitle2"
                        color="primary"
                        fontWeight="bold"
                        sx={{
                            textTransform: "uppercase",
                            opacity: 0
                        }}>
                        Ask This
                    </Typography>
                    <IconButton
                        aria-label='Ask This'
                        disabled={title.template.length === 0}>
                        <ArrowCircleRightOutlined color="primary" fontSize='medium'/>
                    </IconButton>
                </Stack>
            </Stack>
        </Button>
    </>;
};

export default HomePromptItem;
