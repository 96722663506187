import {useEffect, useState} from "react";
import {ServerUrlProvider} from "../utils/server-url-provider";
import {useUserId} from "../hooks/use-user-id";
import {useConversationId} from "../hooks/use-conversation-id";

export const useRemoteAnswerQuestion = ({responses, setResponses}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [question, setQuestion] = useState("");

    const [conversationId] = useConversationId();
    const [userId] = useUserId();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (question && userId && conversationId) {

            setTimeout(async () => {

                const questionId = responses.length + 1;

                const updatedResponses = [{id: questionId, question, answer: ""}, ...responses];

                setResponses(updatedResponses);

                try {
                    const url = ServerUrlProvider("ask");

                    const response = await fetch(url, {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            orignalQuestionByUser: question, // Fix Typo
                            conversationId,
                            userId
                        })
                    });
                    const answer = await response.json();

                    setResponses(updatedResponses.map((item) => {
                        if (item.id === questionId) {
                            item.answer = answer["answer"];
                        }
                        return item;
                    }));

                    setSuggestions(answer["followUpQuestions"]);
                    setQuestion("");
                } catch (error) {
                    setError(error);
                } finally {
                    setLoading(false);
                }
            }, 0);
        }
    }, [setQuestion, question, userId, conversationId]);

    return {suggestions, setSuggestions, setQuestion, loading, error};
}
