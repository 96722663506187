import {Box, IconButton, Paper, Stack} from "@mui/material";
import ChatRecentHeader from "./chat-recent-header";
import ChatRecentList from "./chat-recent-list";
import {MoreHoriz as MoreHorizIcon} from "@mui/icons-material";
import {useRef} from "react";


const ChatRecent = ({headerHeight, isSideBarOpen, onToggleSideBar}) => {

    const headerRef = useRef();

    return <Box
        sx={{
            height: "100%",
            "& > *": {
                transition: "opacity " + (isSideBarOpen ? "0.5s" : "0.1s") + " ease-in",
            }
        }}>
        <Stack
            direction="column"
            spacing={2}
            sx={{
                display: {xs: isSideBarOpen ? "none" : "block", md: "block"},
                zIndex: isSideBarOpen ? "initial" : 1,
                opacity: isSideBarOpen ? 0 : 1,
                position: "fixed",
                left: {xs: "initial", md: 0},
                right: {xs: 0, md: "initial"},
                marginTop: 2,
            }}>
            <Paper
                sx={{
                    borderBottomRightRadius: {xs: 0, md: "25%"},
                    borderTopRightRadius: {xs: 0, md: "25%"},
                    borderBottomLeftRadius: {xs: "25%", md: 0},
                    borderTopLeftRadius: {xs: "25%", md: 0},
                }}>
                <IconButton size="large" onClick={onToggleSideBar}>
                    <MoreHorizIcon color="primary" size="large"/>
                </IconButton>
            </Paper>
        </Stack>
        <Stack
            paddingY={2}
            paddingX={4}
            sx={[
                (theme) => ({
                    display: {xs: isSideBarOpen ? "block" : "none", md: "block"},
                    opacity: isSideBarOpen ? 1 : 0,
                    position: {xs: "fixed", md: "initial"},
                    height: "100%",
                    maxWidth: {xs: "80%", md: "initial"},
                    overflowY: "auto",
                    zIndex: isSideBarOpen ? 1 : "initial",
                    left: {xs: "initial", md: 0},
                    right: {xs: 0, md: "initial"},
                    ...theme.applyStyles('light', {
                        background: `${theme.palette.background.default}`,
                    }),
                })
            ]}>
            <ChatRecentHeader onToggleSideBar={onToggleSideBar} headerRef={headerRef}/>
            <ChatRecentList headerRef={headerRef} headerHeight={headerHeight}/>
        </Stack>
    </Box>;
};

export default ChatRecent;
