import React from 'react';
import { Box, Grid2 as Grid, Typography, Container } from '@mui/material'
import ContactUsForm from './contact-us-form';
import ContactBannerEnd from './contact-banner-end';
import { ContactUsProvider } from '../../../providers/menu/contact-us-provider';

const ContactUsMain = () => {
    const onSubmit = async (formData) => {

        const response = await ContactUsProvider(formData.fullname, formData.contact, formData.message);
        return response
    }

    return (

        <Container maxWidth="lg">
            <Box sx={{ py: 4 }}>
                <Typography variant="h1" sx={{
                    textAlign: 'center',
                    mb: 4,
                    mt: 4,
                    color: 'secondary',
                    fontSize: '60px',
                    fontWeight: '400'
                }}>
                    Contact Us
                </Typography>
            </Box>

            <Grid container>
                <ContactUsForm
                    onFormSubmit={onSubmit}
                />
                <ContactBannerEnd />
            </Grid>
        </Container>

    );
}

export default ContactUsMain;
