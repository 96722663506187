import React, { useState } from 'react';
import { Stack, Grid, Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import videoImage from '../../../assets/video-1920x918.webp';
import { VideoProvider } from '../../../providers/menu/about-us-provider';

const Video = () => {
  const [open, setOpen] = useState(false);

  // YouTube Video
  const [{ youtubeVideoId }] = VideoProvider();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: 'background.default',
        padding: '2rem'
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={12}>
          <Stack spacing={2}>
            <img
              src={videoImage}
              alt="Video"
              style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
              onClick={handleOpen}
            />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  zIndex: 1000,
                }}
              >
                <Stack position="relative" width="100%" maxWidth="800px" height="auto" borderRadius="10px" overflow="hidden">
                  <iframe
                    width="100%"
                    height="100%"
                    style={{ minWidth: '600px', minHeight: '400px' }}
                    src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'white',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Box>
            </Modal>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Video;
