const { useTheme } = require('@mui/material/styles');

const HomeBannerEnd = () => {
    const theme = useTheme();
    return (
        <div sx={{ position: 'relative'}}>
            <svg viewBox="0 0 300 200" sx={{ width: '80%', height: 'auto' }}>
                <circle cx="30" cy="30" r="5" fill={theme.palette.primary.main} style={{
                    transition: 'transform 0.3s ease',
                }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        from="1"
                        to="2"
                        begin="mouseover"
                        end="mouseout"
                        dur="0.3s"
                        fill="freeze"
                    />
                </circle>
            </svg>
            <svg viewBox="0 0 200 50" sx={{ width: '100%', height: 'auto' }}>
                <circle
                    cx="50"
                    cy="30"
                    r="8"
                    fill={theme.palette.primary.main}
                    style={{
                        transition: 'transform 0.3s ease', // Add transition for smooth animation
                    }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        from="1"
                        to="3"
                        begin="mouseover"
                        end="mouseout"
                        dur="0.3s"
                        fill="freeze" // Keep the final state after animation ends
                    />
                </circle>
            </svg>
        </div>
    )
};

export default HomeBannerEnd;
