import {Button, Stack} from "@mui/material";

const ChatMainSuggestions = ({suggestions, setQuestion}) => {

    const onClickSuggestion = (event) => {
        const suggestion = suggestions.find((item) => {
            return item.summary === event.target.innerText;
        })

        setQuestion(suggestion.fullQuestion);
    }

    return <Stack
        direction="row"
        gap={1}
        paddingX={2}
        sx={{
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
        }}>
        {suggestions.map((item, index) => {
            return <Button key={index}
                           onClick={onClickSuggestion}
                           variant="outlined"
                           color="secondary"
                           size="small"
                           sx={{
                               minWidth: "initial",
                               marginY: 1,
                               paddingY: 0.5,
                               paddingX: 1,
                               whiteSpace: "nowrap",
                               textTransform: "initial",
                               borderWidth: "3px",
                               borderRadius: "5px",
                               fontWeight: "bold",
                               display: "inline-block",
                           }}>
                {item.summary}
            </Button>
        })}
    </Stack>;
};

export default ChatMainSuggestions;
