export const UuidProvider = async () => {
    let id;

    if (typeof window.self.crypto !== "undefined") {
        id = window.self.crypto.randomUUID();
    } else {
        const {v4: randomUUID} = await import( 'uuid');
        id = randomUUID();
    }
    return id;
}

