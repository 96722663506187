import {Container, Stack} from '@mui/material';
import HomePromptGetStartedItem from "./home-prompt-get-started-item";
import HomePromptItem from "./home-prompt-item";
import {useRemoteHomePrompts} from "../../../../providers/remote/use-remote-home-prompts";
import Skeleton from '@mui/material/Skeleton';

const HomePrompt = () => {
    const [prompts] = useRemoteHomePrompts();

    return (
        <Container fixed={true} maxWidth="xl">
            <Stack
                gap={3}
                direction="row"
                sx={{
                    justifyContent: 'center',
                    height: {md: 200},
                }}>
                <Stack sx={{
                    padding: 1,
                    display: {
                        xs: "none",
                        md: "flex"
                    }
                }}>
                    <HomePromptGetStartedItem/>
                </Stack>
                <Stack
                    gap={3}
                    direction="row"
                    sx={{
                        paddingY: {md: 1},
                        paddingX: 2,
                        width: "100%",
                        overflowX: 'scroll',
                        flexWrap: 'nowrap',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        msOverflowStyle: "none",
                        scrollbarWidth: 'none'  // Firefox
                    }}>
                    {prompts.length > 0 ?
                        prompts.map((title, index) => {
                            return <HomePromptItem title={title} key={index}/>
                        }) :
                        // Render skeletons while loading
                        Array.from({length: 4}).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant="rectangular"
                                width={300}
                                height={140}
                                sx={{borderRadius: '15px'}}
                            />
                        ))
                    }
                </Stack>
            </Stack>
        </Container>
    )
};

export default HomePrompt;
