import dicommmLogo from "../../assets/dicomm.png";
import totalMediaLogo from "../../assets/totalmedia.png";
import dynatonLogo from "../../assets/dynaton.png";
import braveLogo from "../../assets/brave.png";
import getStarted from "../../assets/get-started-1000x670.webp";

const AboutUsProvider = () => {
    return [
        {
            id: 1,
            title: "About Us",
            subtitle: "We're bringing trust to the digital world.",
            content:
                "Discover a team committed to building a more secure digital environment.",
            image_url: "",
            image_alt: "",
        },
        {
            id: 2,
            title: "About Us",
            subtitle: "We're bringing trust to the digital world.",
            content:
                "Discover a team committed to building a more secure digital environment.",
            image_url: "",
            image_alt: "",
        },
    ];
};

const VideoProvider = () => {
    return [
        {
            id: 1,
            youtubeVideoId: "jMW8_YVFgzY",
        },
    ];
};

const AboutUsSectionProvider = () => {
    return [
        {
            id: 1,
            title: "About Dicomm Group",
            content:
                "At Dicomm Group, we’re more than just an advertising agency, we’re your end-to-end partner for comprehensive brand growth. Through our three specialized subsidiaries: Dicomm McCann, Total Media UM, and Dynatón, we offer everything your brand needs to connect with audiences, grow, and thrive. ",
            action: "Explore",
            action_url: "/chat",
        },
    ];
};

const LogosProvider = () => {
    return [
        {
            name: "Dicomm McCann",
            logo: dicommmLogo,
            url: "https://www.dicomm.co.zw/",
        },
        {
            name: "Total Media UM",
            logo: totalMediaLogo,
            url: "https://www.totalmedia.co.zw/",
        },
        {
            name: "Dynaton Data",
            logo: dynatonLogo,
            url: "https://www.dynatondata.com/",
        },
        {name: "Brave", logo: braveLogo, url: "https://brave.co.zw/"},
    ];
};

const GetStartedProvider = () => {
    return [
        {
            id: 1,
            title: "Sit back, relax we got your answers",
            content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel lectus egestas ex egestas elementum id vitae massa. Sed sollicitudin id tortor nec sagittis. Duis sit amet tempor libero. Fusce fringilla consequat.",
            imageUrl: getStarted,
            imageAlt: "Chat Bubbles",
            buttonText: "Get Started",
            buttonLink: "/chat",
        },
    ];
};

const FAQsProvider = () => {
    return [
        {
            id: 1,
            question: "What does Dicomm Group do?",
            answer: "Dicomm Group is a full-service advertising and marketing agency that helps businesses grow. We offer a wide range of services, including creative strategy, media planning and buying, digital marketing, public relations, and more."
        },
        {
            id: 2,
            question: "Where is Dicomm located?",
            answer: "Our head office is in Harare, Zimbabwe. We also have a presence in South Africa and Zambia."
        },
        {
            id: 3,
            question: "How much do you charge for a campaign?",
            answer: "The cost of a campaign varies depending on the scope of work. We'll work with you to develop a custom proposal that meets your specific needs and budget."
        },
        {
            id: 4,
            question: "What are your services?",
            answer: "We offer a wide range of services, including: \n\n - Creative Strategy \n - Media Planning & Buying \n - Digital Marketing \n - Public Relations \n - Branding \n - Content Marketing \n - Social Media Marketing \n - Event Management"
        }
    ];
};

export {
    AboutUsProvider,
    VideoProvider,
    AboutUsSectionProvider,
    LogosProvider,
    GetStartedProvider,
    FAQsProvider
};
