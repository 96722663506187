import {useEffect, useState} from "react";
import {ServerUrlProvider} from "../utils/server-url-provider";
import {useUserId} from "../hooks/use-user-id";

export const useRemoteConversations = () => {
    const [conversations, setConversations] = useState([]);
    const [userId] = useUserId();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userId) {

            setLoading(true);

            setTimeout(async () => {
                try {

                    const params = new URLSearchParams({
                        userId: userId
                    });

                    const url = ServerUrlProvider("chat/title/all");

                    const response = await fetch(url + "?" + params.toString());
                    const data = await response.json();
                    setConversations(data.reverse());
                } catch (error) {
                    setError(error);
                } finally {
                    setLoading(false);
                }
            }, 0);
        }
    }, [userId]);

    return {conversations, setConversations, loading, error};
};
