import {IconButton, InputAdornment, Stack, TextField} from "@mui/material";
import {ArrowCircleRightOutlined} from "@mui/icons-material";
import {useRef, useState} from "react";

const ChatMainPrompt = ({setQuestion}) => {
    const askQuestionRef = useRef(null)

    const [inputQuestion, setInputQuestion] = useState('')

    const onChangeInputQuestion = (event) => {
        setInputQuestion(event.target.value);
    }

    const onSendQuestion = (event) => {
        setQuestion(inputQuestion);
        setInputQuestion('');
    }

    const handleMessageKeydown = (e) => {
        if (e.shiftKey && e.which === 13) {
            return;
        }
        if (e.which === 13) {
            e.preventDefault();
            if (e.target.value.trim().length !== 0) {
                askQuestionRef.current.click();
            }
        }
    }

    return <Stack sx={[
        (theme) => ({
            marginY: {xs: 1, md: 2},
            border: `9px solid ${theme.palette.background.default}`,
            borderRadius: "20px",
            outline: `1px solid ${theme.palette.primary.main}`,
            ...theme.applyStyles('light', {
                outline: `1px solid #fafafc`,
                border: `9px solid #e8f5ff`,
            })
        })
    ]}>
        <TextField
            variant="standard"
            sx={[
                (theme) => ({
                    borderRight: "none",
                    borderLeft: "none",
                    borderBottom: "9px solid transparent",
                    borderTop: `9px solid ${theme.palette.background.paper}`,
                    backgroundImage: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}), linear-gradient(to right, ${theme.palette.tertiary.main}, ${theme.palette.secondary.main})`,
                    borderRadius: "10px",
                    backgroundOrigin: "border-box",
                    backgroundClip: "padding-box, border-box",
                })
            ]}
            placeholder="Continue Asking"
            value={inputQuestion}
            multiline={true}
            minRows={2}
            autoFocus={true}
            onKeyDown={handleMessageKeydown}
            onChange={onChangeInputQuestion}
            InputProps={{
                disableUnderline: true,
                sx: {
                    paddingY: "20px",
                    paddingLeft: "20px"
                },
                endAdornment: (
                    <InputAdornment position="start">
                        <IconButton
                            ref={askQuestionRef}
                            onClick={onSendQuestion}
                            disabled={inputQuestion.length === 0}
                            sx={[
                                (theme) => ({
                                    background: theme.palette.secondary.main,
                                    "&:hover": {
                                        background: theme.palette.secondary.dark,
                                    }
                                })
                            ]}>
                            <ArrowCircleRightOutlined
                                fontSize="large"
                                sx={[
                                    (theme) => ({
                                        color: theme.palette.secondary.contrastText
                                    })
                                ]}/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}/>
    </Stack>;
};

export default ChatMainPrompt;
