import {Box, Stack} from "@mui/material";
import ChatMainQuestionItem from "./chat-main-question-item";
import ChatMainAnswerItem from "./chat-main-answer-item";
import ChatMainSuggestions from "./chat-main-suggestions";
import ChatMainPrompt from "./chat-main-prompt";
import {useRemoteAnswerQuestion} from "../../../../providers/remote/use-remote-answer-question";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useRemoteConversation} from "../../../../providers/remote/use-remote-conversation";

const ChatMain = () => {

    const {responses, setResponses, conversationId} = useRemoteConversation();

    const {suggestions, setSuggestions, setQuestion} = useRemoteAnswerQuestion({
        responses,
        setResponses
    });

    // Take question from navigation state and clear the state
    const location = useLocation();

    useEffect(() => {

        const {question = "", ...state} = location.state || {};

        if (question.length !== 0) {
            setQuestion(question);
        }

        // eslint-disable-next-line no-restricted-globals
        history.replaceState(state, "");

        setSuggestions([]);

    }, [location.state, setQuestion, setSuggestions]);

    // Clear Suggestions on new chat
    useEffect(() => {
        setSuggestions([]);
    }, [conversationId, setSuggestions]);

    return <Stack
        direction="column"
        sx={{
            paddingX: 1,
            height: "100%"
        }}>
        <Stack direction="column-reverse" sx={{
            height: "inherit",
            overflowY: "auto",
            paddingX: 1,
            paddingBottom: 3
        }}>
            {responses.map((item, index) => {
                return (<Stack
                    key={index}
                    spacing={2}
                    marginTop={8}>
                    <ChatMainQuestionItem question={item.question} questionId={item.id}/>
                    <ChatMainAnswerItem answer={item.answer} loading={item.answer.length === 0 && index === 0}/>
                </Stack>)
            })}
        </Stack>
        <Box sx={{
            marginRight: {xs: -2, md: 8, xl: 0},
        }}>
            <ChatMainSuggestions suggestions={suggestions} setQuestion={setQuestion}/>
            <ChatMainPrompt setQuestion={setQuestion}/>
        </Box>
    </Stack>;
};

export default ChatMain;
