import React from 'react';
import { Stack, Grid, Typography, Container } from '@mui/material';
import { RedirectButton } from '../../buttons/button';
import { AboutUsSectionProvider } from '../../../providers/menu/about-us-provider';

const AboutUs = () => {
  const aboutUsData = AboutUsSectionProvider();
  const aboutUsSection = aboutUsData[0];

  return (
      <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
              backgroundColor: 'background.default',
              padding: '1.5rem'
          }}
      >
          <Container maxWidth="lg">
              <Grid
                  container
                  spacing={4}
                  alignItems="center"
              >
                  <Grid item xs={12} md={6}>
                      <Typography
                          variant="h3"
                          component="h1"
                          gutterBottom
                          sx={{
                              fontWeight: 'bold',
                              color: '#2e3190',
                              textAlign: 'center',
                              fontSize: {
                                  xs: '1.75rem',
                                  sm: '2rem',
                                  md: '2.5rem',
                                  lg: '3rem',
                                  xl: '3.5rem'
                              },
                          }}
                      >
                          {aboutUsSection.title}
                      </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <Typography
                          variant="body1"
                          paragraph
                          sx={{ lineHeight: 1.8 }}
                      >
                          {aboutUsSection.content}
                      </Typography>
                      <RedirectButton
                          redirectTo={aboutUsSection.action_url}
                          title={aboutUsSection.action}
                          variant="contained"
                          sx={{
                              backgroundColor: 'secondary.main',
                              color: 'background.main'
                          }}
                      />
                  </Grid>
              </Grid>
          </Container>
      </Stack>
  );
};

export default AboutUs;
