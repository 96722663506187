import React from 'react';
import { Stack } from '@mui/material';
import Banner from '../../layout/pages/about-us/banner';
import Video from '../../layout/pages/about-us/video';
import AboutUs from '../../layout/pages/about-us/about-us';
import Logos from '../../layout/pages/about-us/logos';
import GetStarted from '../../layout/pages/about-us/get-started';
import FAQs from '../../layout/pages/about-us/faqs';

const About = () => {
  return (
    <Stack spacing={4}>
      <Banner />
      <Video />
      <AboutUs />
      <Logos />
      <GetStarted />
      <FAQs />
    </Stack>
  );
};

export default About;