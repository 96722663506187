import React from 'react';
import { Grid2 as Grid, Typography,Box } from '@mui/material';
const ContactBannerEnd =() => {
    return (
        <Grid item xs={12} md={6}>
            <Box sx={{
                pl: { md: 8 },
                mt: { xs: 4, md: 0 },
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}>
                <Typography
                    variant="body1"
                    sx={{
                        color: 'primary',
                        fontSize: '18px',
                        fontWeight: 400
                    }}
                >
                    21 Lawson Avenue, Harare,
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: 'primary',
                        fontSize: '18px',
                        fontWeight: 400
                    }}
                >
                    Zimbabwe
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: 'primary',
                        fontSize: '16px',
                        fontWeight: 400,
                        mt: 2  // Added extra margin before phone numbers
                    }}
                >
                    +263 (242) 795153/6
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: 'primary',
                        fontSize: '16px',
                        fontWeight: 400
                    }}
                >
                    +263 772 162 686/8
                </Typography>
            </Box>
        </Grid>
    );
}

export default ContactBannerEnd;
