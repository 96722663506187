import React, {useEffect, useState,} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Container, IconButton, InputAdornment, Stack, TextField, Typography} from '@mui/material';
import {alpha, useTheme} from '@mui/material/styles';
import ArrowCircleRightOutlined from '@mui/icons-material/ArrowCircleRightOutlined';
import {UuidProvider} from "../../../../providers/utils/uuid-provider";


function ChatInput({headerRef}) {
    const theme = useTheme();
    const [isCursorVisible, setIsCursorVisible] = useState(true);
    const [question, setQuestion] = useState('')
    const navigate = useNavigate();

    const handleSubmit = async () => {
        navigate('/chat/' + await UuidProvider(), {state: {question}});
    }

    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
    }

    const handleMessageKeydown = (e) => {
        if (e.shiftKey && e.which === 13) {
            return;
        }
        if (e.which === 13) {
            if (e.target.value.trim().length === 0) {
                e.preventDefault();
            } else {
                e.preventDefault();
                handleSubmit();
            }
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsCursorVisible((prev) => !prev);
        }, 500);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Container>
            <Stack
                direction={{xs: 'column', sm: 'row'}}
                alignItems="left"
                sx={[
                    (theme) => ({
                        borderRight: "none",
                        borderLeft: "none",
                        borderBottom: "9px solid transparent",
                        borderTop: `9px solid ${theme.palette.background.paper}`,
                        backgroundImage: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}), linear-gradient(to right, ${theme.palette.tertiary.main}, ${theme.palette.secondary.main})`,
                        backgroundOrigin: "border-box",
                        backgroundClip: "padding-box, border-box",
                        paddingY: 2,
                        paddingX: 3,
                        borderRadius: 2,
                        margin: '30px auto',
                        boxShadow: '1px 1px 500px ' + alpha(theme.palette.primary.main, 0.2),
                        backgroundColor: theme.palette.background.paper,
                    })
                ]}
            >
                <Box sx={{
                    flexGrow: 1,
                    backgroundColor: theme.palette.background.paper,
                }}>
                    <Typography variant="body1" sx={{
                        justifyContent: 'left',
                        textAlign: 'left',
                        fontSize: '17px',
                        fontWeight: 'bold',
                        color: theme.palette.secondary.main,
                        backgroundColor: theme.palette.background.paper
                    }}>
                        How can I help you today?
                    </Typography>
                    <TextField
                        onChange={handleQuestionChange}
                        fullWidth
                        placeholder="Ask anything..."
                        variant="standard"
                        type="search"
                        multiline={true}
                        onKeyDown={handleMessageKeydown}
                        minRows={2}
                        autoFocus={true}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isCursorVisible &&
                                        <span style={{fontSize: '1.2em'}}></span>} {/* Blinking cursor */}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ml: 2}}> {/* Margin left for spacing */}
                    <IconButton
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.background.paper,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.dark,
                                color: theme.palette.background.paper
                            },
                            padding: '15px'
                        }}
                        disabled={question.trim().length === 0}
                        onClick={handleSubmit}>
                        <ArrowCircleRightOutlined sx={{color: theme.palette.background.paper}} fontSize='large'/>
                    </IconButton>
                </Box>
            </Stack>
        </Container>
    );
}

export default ChatInput;
