const MenuFooterProvider = () => {
    return [
        {
            title: "Privacy Policy",
            path: "/privacy-policy",
        },
        {
            title: "Dicomm McCann",
            path: "https://www.dicommm.co.zw/",
        },
        {
            title: "Total Media",
            path: "https://www.totalmedia.co.zw/",
        },
        {
            title: "Dynaton",
            path: "https://www.dynatondata.com",
        },
        {
            title: "Brave Studio",
            path: "https://brave.co.zw/",
        },
        {
            title: "Blog",
            path: "/blog",
        },
        {
            title: "English (English)",
            path: "#",
        }
    ];
}

export {MenuFooterProvider};
