import React from 'react';
import {
  Stack,
  Typography,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FAQsProvider } from '../../../providers/menu/about-us-provider';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  marginBottom: theme.spacing(5), // Add spacing between accordions
  '& .MuiAccordionSummary-root': {
    padding: 2,
    borderBottom: '1px solid #ccc',
    '& .MuiAccordionSummary-content': {
      margin: 1,
    },
    '& .Mui-expanded': {
      margin: 1,
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: 10,
    borderTop: '1px solid #ccc',
  },
  color: '#3D4F71',
}));

const FAQs = () => {
  const faqsData = FAQsProvider();
  
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: 'background.paper',
        minHeight: 400,
        pt: '100px',
        pb: '100px'
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" sx={{ fontSize: '3rem', marginBottom: '2rem', fontWeight: '400', marginTop: '2rem' }} color='primary'>
          FAQs
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            {faqsData.map((faq) => ( // Map through the faqsData array
              <StyledAccordion key={faq.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography color='secondary'>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color='secondary'>
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" justifyContent="center" >
              <Typography color='secondary' variant="body1" paragraph justifyContent="center" sx={{ lineHeight: 1.8 }} p={4}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel lectus egestas ex egestas elementum id vitae massa. Sed sollicitudin id tortor nec sagittis. Duis sit amet tempor libero. Fusce fringilla consequat ex vel maximus. Ut malesuada ultrices finibus. Donec quis diam lorem. Curabitur commodo commodo libero ut iaculis. Duis luctus neque at libero convallis, et tempus nunc tincidunt. Fusce sed blandit ipsum. Nunc sodales semper euismod. Curabitur tristique nisi lorem, et vehicula dui molestie facilisis.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
};

export default FAQs;