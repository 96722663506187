import * as React from 'react';
import {forwardRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import logo from '../../assets/dicomm-ai-logo.png';
import {AppBar, Box, Divider, IconButton, Link, Menu, MenuItem, Stack, Toolbar, useColorScheme} from '@mui/material';
import {Menu as MenuIcon} from "@mui/icons-material";
import {MenuHeaderProvider} from "../../providers/menu/menu-header-provider";
import {HeaderDarkModeButton, HeaderGetStartedButton, HeaderLinkButton} from "./buttons";

const Header = forwardRef(({}, ref) => {

    const {mode, setMode, setColorScheme} = useColorScheme();

    const onToggleTheme = (event) => {
        const mode = event.target.checked ? "dark" : "light";
        setMode(mode);
        setColorScheme(mode);
    }

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const menuHeader = MenuHeaderProvider();

    return (
        <AppBar
            ref={ref}
            position="sticky"
            sx={[
                (theme) => ({
                    paddingY: {xs: 0.5, sm: 1, md: 2},
                    backgroundColor: theme.palette.background.paper
                })
            ]}>
            <Toolbar sx={{width: "100%", justifyContent: "space-between"}} disableGutters>
                <Stack alignItems="center" marginLeft={{xs: 3, md: 12, lg: 16, xl: 24}}>
                    <Link component={RouterLink} to="/">
                        <Box component="img" src={logo} sx={{maxHeight: "50px"}} alt="logo"/>
                    </Link>
                </Stack>

                <Stack direction={{xs: "row-reverse", lg: "row"}} alignItems="center" flexGrow={1}>

                    <Stack
                        direction="row"
                        alignItems="center"
                        flexGrow={3}
                        spacing={4}
                        marginX={{xs: 4, lg: 8}}
                        justifyContent="flex-end"
                        sx={{display: {xs: 'none', lg: 'flex'}}}>
                        {menuHeader.map((link, index) => {
                            return <HeaderLinkButton link={link} key={index}/>
                        })}
                    </Stack>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleOpenNavMenu}
                        sx={{mr: 2, display: {xs: 'flex', lg: 'none'}}}
                    >
                        <MenuIcon color="primary"/>
                    </IconButton>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        padding={2}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}>
                        {menuHeader.map((link, index) => {
                            return <MenuItem key={index} onClick={handleCloseNavMenu} sx={{justifyContent: "center"}}>
                                <HeaderLinkButton link={link} index={index}/>
                            </MenuItem>
                        })}
                        <Divider sx={{display: {xs: "flex", sm: "none"}}}/>
                        <MenuItem sx={{display: {xs: "flex", sm: "none"}, justifyContent: "center"}}>
                            <HeaderGetStartedButton/>
                        </MenuItem>
                        <MenuItem sx={{display: {xs: "flex", sm: "none"}, justifyContent: "center"}}>
                            <HeaderDarkModeButton
                                mode={mode}
                                onToggleTheme={onToggleTheme}/>
                        </MenuItem>
                    </Menu>

                    <Stack
                        direction="row"
                        flexGrow={{xs: 0, lg: 1}}
                        marginX={{sm: 1, xl: 2}}
                        alignItems="center"
                        justifyContent="space-around">
                        <HeaderGetStartedButton sx={{
                            display: {
                                xs: "none", sm: "flex"
                            }
                        }}/>
                        <HeaderDarkModeButton
                            sx={{
                                display: {
                                    xs: "none", sm: "flex"
                                }
                            }}
                            mode={mode}
                            onToggleTheme={onToggleTheme}/>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
});

export default Header;
