import { Button, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ArrowOutward } from '@mui/icons-material';

export const RedirectButton = ({ title, redirectTo, variant, sx = {} }) => {
    return (
        <Box sx={{ mt: 2 }}>
            <Button
                component={RouterLink}
                to={redirectTo}
                endIcon={<ArrowOutward />}
                variant={variant}
                sx={{
                    ...sx,
                    textTransform: 'none',
                    borderRadius: '28px',
                    fontSize: '16px',
                    fontWeight: 500,
                }}>
                {title}
            </Button>
        </Box>
    )
}