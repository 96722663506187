import {useEffect, useState} from "react";
import {PromptProvider} from "../menu/prompt-cards-provider";

export const useRemoteHomePrompts = () => {
    const [prompts, setPrompts] = useState([]);

    useEffect(() => {
        setTimeout(async () => {
            try {
                setPrompts(await PromptProvider());
            } catch (error) {
                console.error('Error fetching prompts:', error);
            }
        }, 0);
    }, []);

    return [prompts, setPrompts];
}
