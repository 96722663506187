import HomeBannerStart from "./home-banner-start";
import HomeBannerCenter from "./home-banner-center";
import HomeBannerEnd from "./home-banner-end";
import {Grid2 as Grid} from "@mui/material";


const HomeBanner = () => {
    return (
        <Grid container spacing={1} columns={24}>
            <Grid size={{xs: 3}}>
                <HomeBannerStart/>
            </Grid>
            <Grid size={{xs: 18}}>
                <HomeBannerCenter/>
            </Grid>
            <Grid size={{xs: 3}}>
                <HomeBannerEnd/>
            </Grid>
        </Grid>
    );
};

export default HomeBanner;
