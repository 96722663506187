import {createTheme} from "@mui/material";

const AppThemeProvider = () => {
    let fontFamily = [
        'Montserrat', 'system-ui'
    ].join(",");

    return createTheme({
        colorSchemes: {
            light: {
                palette: {
                    primary: {
                        main: "#3e5070",
                    },
                    secondary: {
                        main: "#3c7af1",
                    },
                    tertiary: {
                        main: "#2e348e",
                    },
                    background: {
                        default: "#f2f9fe",
                        paper: "#ffffff"
                    },
                },
            },
            dark: {
                palette: {
                    primary: {
                        main: "#fafafc",
                    },
                    secondary: {
                        main: "#f7f7f8",
                    },
                    tertiary: {
                        main: "#ffffff",
                    },
                    background: {
                        default: "#212121",
                        paper: "#2c2c2c",
                    },
                },
            },
        },
        typography: {
            fontFamily
        },
    });
}

export default AppThemeProvider;
