import {ServerUrlProvider} from "./../utils/server-url-provider"

async function ContactUsProvider(fullname, contact, message) {
    try {
        const url = ServerUrlProvider('/support');
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fullname,
                contact,
                message
            })
        })
        return await response.json()
    } catch (error) {
        console.error(error);
        return error;
    }

}

export {ContactUsProvider}
