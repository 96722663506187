import {Icon, Stack, Typography} from "@mui/material";
import {AccountCircleOutlined} from "@mui/icons-material";
import DOMPurify from 'dompurify';
import {marked} from "marked";

const ChatMainQuestionItem = ({question, questionId}) => {

    const htmlQuestion = DOMPurify.sanitize(marked.parse(question));

    return <Stack
        direction="row"
        gap={1}
        id={questionId}
        alignItems="center">
        <Icon fontSize="large">
            <AccountCircleOutlined fontSize="large" color="primary"/>
        </Icon>
        <Typography
            marginY="auto"
            fontSize="large"
            variant="subtitle1"
            align="left"
            color="primary"
            sx={{
                fontWeight: "bold"
            }}
            dangerouslySetInnerHTML={{__html: htmlQuestion}}/>
    </Stack>;
};

export default ChatMainQuestionItem;
