import {useEffect} from "react";
import {FingerprintProvider} from "../utils/fingerprint-provider";
import useLocalStorage from "./use-local-storage";

export const useUserId = () => {
    const [userId, setUserId] = useLocalStorage("user-id", null);

    // Ensure that a question id is set
    useEffect(() => {
        setTimeout(async () => {
            if (!userId) {
                setUserId(await FingerprintProvider());
            }
        }, 0);
    }, [setUserId, userId]);

    return [userId, setUserId];
}
