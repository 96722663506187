import {useEffect, useState} from "react";
import {UuidProvider} from "../utils/uuid-provider";

export const useNewConversationId = () => {
    const [conversationId, setConversationId] = useState(null);

    // Ensure that a conversation id is set
    useEffect(() => {
        setTimeout(async () => {
            if (!conversationId) {
                setConversationId(await UuidProvider());
            }
        }, 0);
    }, [conversationId, setConversationId]);

    return [conversationId, setConversationId];
}
