import {Box, Icon, Stack, Typography} from "@mui/material";
import {Person} from "@mui/icons-material";
import DOMPurify from 'dompurify';
import {marked} from "marked";

const ChatMainAnswerItem = ({answer, loading}) => {

    const htmlAnswer = DOMPurify.sanitize(marked.parse(answer));

    return <Stack
        direction="column">
        <Stack
            gap={1}
            direction="row"
            alignItems="flex-start">
            <Icon fontSize="large">
                <Person fontSize="large" color="primary"/>
            </Icon>
            <Typography
                marginY="auto"
                variant="subtitle1"
                align="left"
                color="primary"
                sx={{
                    fontWeight: "bold"
                }}>
                Answer
            </Typography>
        </Stack>
        <Stack marginLeft={5} marginTop={1}>
            {loading ?
                <Box sx={[
                    (theme) => ({
                        width: "100px",
                        height: "20px",
                        display: "grid",
                        color: theme.palette.primary.main,
                        "&:before, &:after": {
                            content: "\"\"",
                            gridArea: "1/1",
                            "--c1": "linear-gradient(90deg,#0000 calc(100%/3),currentColor 0 calc(2*100%/3),#0000 0)",
                            "--c2": "linear-gradient( 0deg,#0000 calc(100%/3),currentColor 0 calc(2*100%/3),#0000 0)",
                            background: "var(--c1), var(--c2), var(--c1), var(--c2)",
                            backgroundSize: "300% 4px,4px 300%",
                            backgroundRepeat: "no-repeat",
                            animation: "loading-animation 1.5s infinite"
                        },
                        "&:after": {
                            animationDelay: "-.75s"
                        },
                    })
                ]}></Box> :
                <Typography align="left" color="primary" dangerouslySetInnerHTML={{__html: htmlAnswer}}/>}
        </Stack>
    </Stack>;
};

export default ChatMainAnswerItem;
