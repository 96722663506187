import React from 'react';
import {Stack} from "@mui/material";
import HomeBanner from "../../layout/pages/home/banner";
import HomePrompt from "../../layout/pages/home/prompt";
import HomeChat from "../../layout/pages/home/chat-input";
import {useHeaderHeight} from "../../providers/hooks/use-header-height";


const PageHome = ({headerRef}) => {

    const {headerHeight} = useHeaderHeight({headerRef, initialHeight: 96});

    return (<Stack
        sx={{
            height: `calc(100vh - ${headerHeight}px)`,
        }}>
        <Stack sx={{
            flexGrow: 1,
            height: "100%",
            justifyContent: "space-around",
        }}>
            <HomeBanner/>
            <HomePrompt/>
        </Stack>
        <HomeChat/>
    </Stack>);
};

export default PageHome;
