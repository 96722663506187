import { useTheme } from '@mui/material/styles';

const HomeBannerStart = () => {
    const theme = useTheme();
    return (
        <div>
            <svg width="300" height="200">
                <circle
                    cx="50"
                    cy="30"
                    r="8"
                    fill={theme.palette.primary.main}
                    style={{
                        transition: 'transform 0.3s ease', // Add transition for smooth animation
                    }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        from="1"
                        to="3"
                        begin="mouseover"
                        end="mouseout"
                        dur="0.3s"
                        fill="freeze" // Keep the final state after animation ends
                    />
                </circle>
            </svg>

            {/* Second SVG */}
            <svg width="200" height="50">
                <circle cx="80" cy="10" r="5" fill="primary" style={{
                    transition: 'transform 0.3s ease',
                }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        from="1"
                        to="2"
                        begin="mouseover"
                        end="mouseout"
                        dur="0.3s"
                        fill="freeze"
                    />
                </circle>
            </svg>
        </div>
    );
};

export default HomeBannerStart;
