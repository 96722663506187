import {Button, IconButton, Stack, Typography} from "@mui/material";
import {ArrowCircleRightOutlined} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import {useNewConversationId} from "../../../../providers/hooks/use-new-conversation-id";

const HomePromptGetStartedItem = () => {
    const [conversationId] = useNewConversationId();

    return <Button
        component={RouterLink}
        variant="contained"
        color="secondary"
        to={"/chat/" + conversationId}
        sx={[
            (theme) => ({
                height: 140,
                minWidth: 240,
                display: 'inline-flex',
                padding: 0,
                flexDirection: 'column',
                borderRadius: 2,
                "&:hover": {
                    backgroundImage: `linear-gradient(to top right, ${theme.palette.tertiary.main}, ${theme.palette.secondary.main})`,
                    "& .title-get-started": {
                        px: 1
                    }
                },
                "&, & *": {
                    transition: 'all 0.25s linear',
                }
            })
        ]}>
        <Typography
            variant="inherit"
            paddingX={3}
            paddingTop={3}
            sx={[
                (theme) => ({
                    width: "100%",
                    fontWeight: 'bold',
                    fontSize: '15px',
                    textAlign: 'left',
                    color: theme.palette.background.default,
                    textTransform: 'uppercase',
                })
            ]}>
            Some<br/>
            Common<br/>
            Prompts
        </Typography>
        <Stack
            paddingX={3}
            paddingBottom={1}
            className="title-get-started"
            sx={{
                width: "100%",
                justifyContent: 'flex-end',
                flexDirection: 'row',
                flexWrap: 'nowrap'
            }}>
            <IconButton aria-label='Ask This'>
                <ArrowCircleRightOutlined
                    fontSize='medium'
                    sx={[
                        (theme) => ({
                            color: theme.palette.secondary.contrastText,
                        })
                    ]}/>
            </IconButton>
        </Stack>
    </Button>;
};

export default HomePromptGetStartedItem;
