import { Stack } from '@mui/material';
import React from 'react';
import ContactUsMain from '../../layout/pages/contact-us';

const Contact = () => {
    return (<Stack>
        <ContactUsMain/>
    </Stack>);

};

export default Contact;
