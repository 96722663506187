import {Dialog, IconButton, Stack} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";

const AuthenticationDialog = ({dialogOpen, onToggleDialog}) => {
    return <Dialog open={dialogOpen}>
        <Stack direction="row" justifyContent="flex-end">
            <IconButton
                onClick={onToggleDialog}
                sx={(theme) => ({})}>
                <CloseIcon/>
            </IconButton>
        </Stack>
    </Dialog>
}

export default AuthenticationDialog;
