import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {CssBaseline, Stack, ThemeProvider} from '@mui/material';
import Header from './layout/header/header';
import PageHome from './pages/home/page-home';
import About from './pages/about/about';
import HowTo from './pages/how-to-get-started/how-to';
import Contact from './pages/contact/contact';
import Footer from "./layout/footer/footer";
import PageChat from "./pages/chat/page-chat";
import appThemeProvider from "./providers/theme/app-theme-provider"
import {useRef} from "react";

function App() {

    const headerRef = useRef();

    return (
        <ThemeProvider theme={appThemeProvider()}>
            <Stack
                direction="column"
                className="App"
                sx={[
                    (theme) => ({
                        minHeight: '100vh',
                        ...theme.applyStyles('light', {
                            backgroundImage: `linear-gradient(to bottom, white, ${theme.palette.background.default})`
                        }),
                        "& ::-webkit-scrollbar-thumb": {
                            background: `${theme.palette.primary.main}`,
                            borderRadius: "3px"
                        },
                        "& ::-webkit-scrollbar": {
                            height: "5px",
                            width: "5px"
                        }
                    })
                ]}>
                <Router>
                    <CssBaseline/>
                    <Header ref={headerRef}/>
                    <Stack
                        direction="column"
                        sx={{
                            flexGrow: 1
                        }}>
                        <Stack
                            direction="column"
                            sx={{
                                flexGrow: 1
                            }}>
                            <Routes>
                                <Route path="/" element={<PageHome headerRef={headerRef}/>}/>
                                <Route path="/chat/:conversationId" element={<PageChat headerRef={headerRef}/>}/>
                                <Route path="/about-us" element={<About/>}/>
                                <Route path="/how-to-get-started" element={<HowTo/>}/>
                                <Route path="/contact-us" element={<Contact/>}/>
                            </Routes>
                        </Stack>
                        <Footer headerRef={headerRef}/>
                    </Stack>
                </Router>
            </Stack>
        </ThemeProvider>
    );
}

export default App;
