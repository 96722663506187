import {useCallback, useLayoutEffect, useState} from "react";

export const useHeaderHeight = ({headerRef, initialHeight, offsetHeight = 0}) => {
    const [headerHeight, setHeaderHeight] = useState(initialHeight); // magic number?

    const onResize = useCallback(() => {
        setHeaderHeight(headerRef.current.offsetHeight + offsetHeight);
    }, [headerRef, offsetHeight]);

    useLayoutEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [headerRef, headerHeight, onResize]);

    useLayoutEffect(onResize, [onResize]);

    return {headerHeight, headerRef};
}
