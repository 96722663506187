import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import bg from "../../../assets/about-us-bg-1920x1059.webp";
import { AboutUsProvider } from "../../../providers/menu/about-us-provider";

const Banner = () => {
    const aboutUsData = AboutUsProvider();
    const {subtitle, content} = aboutUsData[0]; // Pick the first object

    return (
        <Grid
            container
            sx={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
                width: "100vw",
                marginLeft: "-50vw",
                marginRight: "-50vw",
                left: "50%",
                transform: "translateX(-50%)",
                minHeight: "100vh",
                mb: "100px",
                mt: "200px",
            }}
        >
            <Container
                maxWidth="lg"
                sx={{ position: "relative", zIndex: 1, textAlign: "center" }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{
                                color: "white",
                                mt: "100px",
                                mb: "30px",
                                fontWeight: "600",
                                fontSize: {
                                    xs: '2.5rem',
                                    sm: '3.1rem',
                                    md: '3.7rem',
                                    lg: '4.3rem',
                                    xl: '5rem'
                                }
                            }}
                        >
                            {subtitle}
                        </Typography>
                        <Typography
                            variant="body1"
                            paragraph
                            sx={{ 
                                color: "white",
                                fontSize: {
                                    xs: '1rem',
                                    sm: '1.1rem',
                                    md: '1.25rem',
                                    lg: '1.9rem',
                                    xl: '2.5rem'
                                }
                            }}
                        >
                            {content}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default Banner;
