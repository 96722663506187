import {useCallback, useEffect, useState} from "react";

function useLocalStorage(key, initialValue) {
    const readValue = useCallback(() => {

        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error('Error reading local storage key “' + key + '”:', error);
            return initialValue;
        }
    }, [initialValue, key]);

    const [storedValue, setStoredValue] = useState(readValue);

    const setValue = (value) => {
        try {
            // Allow value to be a function like in useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);

            localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error('Error setting local storage key “' + key + '”:', error);
        }
    };

    useEffect(() => {
        setStoredValue(readValue());
    }, [key, readValue]);

    return [storedValue, setValue];
}

export default useLocalStorage
