const HomeTitleSubtitle = () => {
    return [
        {
            id: 1,
            title: "Discover & dominate new markets across the world.",
            subtitle: "Our Creative Online & Offline Advertising, Media buying & Custom Software Development solutions will get the job done",
        }
    ];
};

export { HomeTitleSubtitle };
