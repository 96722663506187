import {Container, Stack, Typography} from "@mui/material";
import {HomeTitleSubtitle} from "../../../../providers/menu/home-titles-subtitles-provider";
import {animated, config, useSpring, useTrail} from '@react-spring/web';

const titlesSubtitles = HomeTitleSubtitle();

const HomeBannerCenter = () => {
    const trail = useTrail(titlesSubtitles.length, {
        from: {opacity: 0, transform: 'scale(0.8)'},
        to: {opacity: 1, transform: 'scale(1)'},
        config: {mass: 1, tension: 280, friction: 60},
        delay: index => index * 50 + 10, // Delay the animation slightly
    });

    const textAnimation = useSpring({
        from: {width: '0%'},
        to: {width: '100%'},
        config: config.molasses, // Adjust the animation config as needed
        delay: 300, // Delay after the initial popup
    });

    return (
        <Stack sx={{
            height: "100%",
        }}>
            {trail.map((style, index) => (
                <Stack component={animated.div}
                       sx={{
                           gap: 1,
                           height: "100%",
                           justifyContent: "space-around",
                       }}
                       key={titlesSubtitles[index].id}
                       style={style}>
                    <Typography
                        variant="h2"
                        id={titlesSubtitles[index].id}
                        color="tertiary"
                        sx={{
                            fontWeight: '600',
                            fontSize: {
                                xs: '1.75rem',
                                sm: '2rem',
                                md: '2.5rem',
                                lg: '3rem',
                                xl: '3.5rem'
                            },
                            textAlign: 'center',
                            overflow: 'hidden',
                            whiteSpace: 'wrap'
                        }}
                    >
                        <animated.span style={textAnimation}>
                            {titlesSubtitles[index].title}
                        </animated.span>
                    </Typography>
                    <Container fixed={true}>
                        <Typography
                            variant="h3"
                            id={titlesSubtitles[index].id}
                            color="primary"
                            sx={{
                                marginX: 'auto',
                                marginTop: {md: 3},
                                display: 'block',
                                textAlign: 'center',
                                fontSize: {
                                    xs: '0.8rem',
                                    sm: '1rem',
                                    md: '1.2',
                                    lg: '1.4rem',
                                    xl: '1.7rem'
                                },
                            }}>
                            {titlesSubtitles[index].subtitle}
                        </Typography>
                    </Container>
                </Stack>
            ))
            }
        </Stack>
    );
};

export default HomeBannerCenter;
