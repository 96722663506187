import {useEffect, useState} from "react";
import {ServerUrlProvider} from "../utils/server-url-provider";
import {useConversationId} from "../hooks/use-conversation-id";
import {useUserId} from "../hooks/use-user-id";

export const useRemoteConversationTitle = () => {
    const [conversationTitle, setConversationTitle] = useState("New Chat!!");

    const [conversationId] = useConversationId();
    const [userId] = useUserId();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userId && conversationId) {

            setLoading(true);
            setConversationTitle("New Chat!!");

            setTimeout(async () => {
                try {

                    const params = new URLSearchParams({
                        userId,
                        conversationId
                    });

                    const url = ServerUrlProvider("chat/title");

                    const response = await fetch(url + "?" + params.toString());
                    const {chatTitle = ""} = await response.json();

                    if (chatTitle) {
                        setConversationTitle(chatTitle);
                    }
                } catch (error) {
                    setError(error);
                } finally {
                    setLoading(false);
                }
            }, 0);
        }
    }, [conversationId, userId]);

    return {conversationTitle, conversationId, loading, error};
};
