import React from 'react';
import { Typography, Box, Link, Container, Grid } from '@mui/material';
import { LogosProvider } from '../../../providers/menu/about-us-provider';

const companiesData = LogosProvider();
const companies = companiesData.map((company) => ({
  name: company.name,
  logo: company.logo,
  url: company.url,
}));

const Logos = () => {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', py: 8 }}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-around" alignItems="center">
          {companies.map((company) => (
            <Grid item key={company.name} xs={12} sm={6} md={4} lg={3} sx={{ textAlign: 'center' }}>
              <Link
                href={company.url}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <img
                  src={company.logo}
                  alt={company.name}
                  style={{ maxWidth: '150px', height: 'auto' }}
                />
              </Link>
              <Link
                href={company.url}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Typography variant="body2" sx={{ color: 'grey', mt: 1 }}>
                  Visit →
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Logos;