import React from 'react';
import { Stack, Typography, Grid, Container } from '@mui/material';
import { RedirectButton } from '../../buttons/button';
import { GetStartedProvider } from '../../../providers/menu/about-us-provider';

const GetStarted = () => {
  const [{ title, content, imageUrl, imageAlt, buttonText, buttonLink }] = GetStartedProvider();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: '#3476f2',
        minHeight: 400,
        pt: '100px',
        pb: '100px'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center" >
          <Grid item xs={12} md={5}>
            <Stack spacing={2} padding={2}>
              <Typography variant="h2" color="white" align="left" sx={{ fontWeight: 600, fontSize: '40px' }}>
                {title}
              </Typography>
              <Typography variant="body1" color="white" align="left" padding={1} sx={{ lineHeight: 1.8 }}>
                {content}
              </Typography>
              <RedirectButton title={buttonText} redirectTo={buttonLink} variant="outlined" sx={{ backgroundColor: 'transparent', color: 'white', borderColor: 'white' }} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <img src={imageUrl} style={{ width: '100%', height: 'auto' }} alt={imageAlt} />
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
};

export default GetStarted;