import {Icon, Stack, Typography} from "@mui/material";
import {ChatBubbleOutline} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import {useHeaderHeight} from "../../../../providers/hooks/use-header-height";
import {useRemoteConversations} from "../../../../providers/remote/use-remote-conversations";
import {useEffect} from "react";
import {useRemoteConversationTitle} from "../../../../providers/remote/use-remote-conversation-title";

const ChatRecentList = ({headerHeight: offsetHeight, headerRef}) => {

    const {conversations, setConversations, loading, error} = useRemoteConversations();

    const {conversationTitle, conversationId} = useRemoteConversationTitle();

    useEffect(() => {
        const conversation = conversations.find((item) => item.conversation_id === conversationId);

        if (!conversation) {
            setConversations([{
                title: conversationTitle,
                conversation_id: conversationId
            }, ...conversations]);
        } else {
            setConversations([{
                ...conversation,
                title: conversationTitle
            }, ...conversations.filter((item) => item.conversation_id !== conversationId)]);
        }
    }, [conversationId, conversationTitle]);

    const {headerHeight} = useHeaderHeight({headerRef, initialHeight: 0, offsetHeight});

    return <Stack spacing={1}>
        <Typography
            variant="subtitle1"
            color="primary"
            align="left"
            sx={{
                fontWeight: "bold"
            }}>
            Recent
        </Typography>
        <Stack
            gap={1}
            sx={{
                height: `calc(100vh - ${headerHeight}px - 5rem)`,
                overflowY: "auto"
            }}>
            {conversations.map((item, index) => {
                return <Stack
                    key={index}
                    direction="row"
                    spacing={1}
                    component={RouterLink}
                    to={`/chat/${item.conversation_id}`}
                    sx={{
                        textDecoration: 'none',
                        display: "flex"
                    }}>
                    <Icon>
                        <ChatBubbleOutline fontSize="small" color="primary"/>
                    </Icon>
                    <Typography
                        align="left"
                        color="primary"
                        noWrap
                    >
                        {item.title}
                    </Typography>
                </Stack>;
            })}
        </Stack>
    </Stack>;
};

export default ChatRecentList;
