import {useEffect, useState} from "react";
import {ServerUrlProvider} from "../utils/server-url-provider";
import {useUserId} from "../hooks/use-user-id";
import {useConversationId} from "../hooks/use-conversation-id";

export const useRemoteConversation = () => {
    const [responses, setResponses] = useState([]);

    const [userId] = useUserId();
    const [conversationId] = useConversationId();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userId && conversationId) {

            setLoading(true);
            setResponses([]);

            setTimeout(async () => {
                try {

                    const url = ServerUrlProvider("chat/" + conversationId);

                    const response = await fetch(url);
                    const data = await response.json();

                    if (data.length !== 0) {
                        setResponses(data.reverse().map((item, index) => {
                            return {
                                id: data.length - index,
                                question: item.question,
                                answer: item["assistant_answer"],
                            };
                        }));
                    }
                } catch (error) {
                    setError(error);
                } finally {
                    setLoading(false);
                }
            }, 0);
        }
    }, [userId, conversationId]);

    return {responses, setResponses, conversationId, loading, error};
};
